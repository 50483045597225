import { SlugParamKey, SlugParamMappingType } from '../../Entities/Search/QueryParams.entity';

const SLUG_PARAM_KEYS: SlugParamKey[] = [
  'make',
  'model',
  'trim',
  'body',
  'year',
  'pricemin',
  'pricemax',
  'fueltype',
  'color',
  'cc',
  'seats',
  'doors',
  'gearbox',
  'page',
  'promotions_exclusives',
];

const slugParamMapping: SlugParamMappingType = {
  make: ['make'],
  model: ['model'],
  trim: ['variant-type'],
  body: ['body-type'],
  year: ['year__gte', 'year__lte'],
  pricemin: ['price__gte'],
  pricemax: ['price__lte'],
  fueltype: ['fuel-type'],
  color: ['color'],
  cc: ['engine-size__gte', 'engine-size__lte'],
  seats: ['seats'],
  doors: ['doors'],
  gearbox: ['gear-box'],
  page: ['page'],
  promotions_exclusives: ['has-offers-or-promotions'],
};

const sizeMap = {
  uk: 'page_size',
  fr: 'size',
  de: 'size',
};
const DEFAULT_PAGE = {
  uk: 1,
  fr: 0,
  de: 0,
};

const NUM_RESULTS = 18;

const DEALERS_NUM_RESULTS = 24;

const VEHICLE_STATUS_VALUES = {
  includeSold: 'active,inactive',
  excludeSold: 'active',
};

export {
  DEALERS_NUM_RESULTS,
  DEFAULT_PAGE,
  NUM_RESULTS,
  sizeMap,
  SLUG_PARAM_KEYS,
  slugParamMapping,
  VEHICLE_STATUS_VALUES,
};
